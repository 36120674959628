import { createRouter, createWebHistory } from 'vue-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      component: () => import('../views/HomePage.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      component: () => import('../views/LoginPage.vue')
    },
    {
      path: "/robots.txt",
      component: () => import('../views/robots.vue')
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../components/404.vue')
    }
  ]
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListner = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListner();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      next('/login')
    }
  }
  else {
    next();
  }
});

export default router;